import React from 'react';
import AdSense from 'react-adsense';

const MyAdSense = () => (
  <AdSense.Google
    client='ca-pub-9677238417081136'
    slot='7806394673'
  />
);

export default MyAdSense;